import React from 'react'
import { ExplanationState, UserDayShift } from '../../api/response'
import dayjs from 'dayjs'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { useStateDispatchContext } from '../../state/stateContext'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'

const strings = {
    label: {
        title: (fullName: string): string => `Wyjaśnienie zakończenia czasu pracy dla ${fullName}`,
        explanationState: (state: ExplanationState): string => {
            switch (state) {
                case ExplanationState.Awaiting:
                    return 'Oczekuje'
                case ExplanationState.Rejected:
                    return 'Odrzucone'
                case ExplanationState.Accepted:
                    return 'Zaakceptowane'
            }

            return '-'
        },
        start: 'Rozpoczęcie czasu pracy',
        end: 'Zakończenie czasu pracy',
        licensePlate: 'Numer rejestracyjny',
        licensePlateValid: 'Poprawny',
        licensePlateInvalid: 'Błędny',
        licensePlateNone: 'Brak',
        explanataion: 'Powód:',
        state: 'Aktualny status',
    },
    button: {
        cancel: 'Anuluj',
        accept: 'Zaakceptuj',
        reject: 'Odrzuć',
    },
}

export interface UserShiftEndExplanationDialogData {
    scheduleId: string
    user: { id: number; fullName: string }
    shift: UserDayShift
    onSuccess: (shift: UserDayShift) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: UserShiftEndExplanationDialogData
    readonly closeDialog: () => void
}

const UserShiftEndExplanationDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { shift, user, onSuccess } = data
    const explanation = shift.endExplanation
    const start = dayjs(shift.start)
    const end = dayjs(shift.end)

    if (explanation === null || end === null) {
        return null
    }

    const { appDispatch } = useStateDispatchContext()

    function handleAcceptClick(): void {
        appDispatch(setInProgress())
        API.shift
            .explanationAccept(data.shift.uuid)
            .then((shift) => {
                onSuccess(shift)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    function handleRejectClick(): void {
        appDispatch(setInProgress())
        API.shift
            .explanationReject(data.shift.uuid)
            .then((shift) => {
                onSuccess(shift)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title(user.fullName)}</DialogTitle>
            <DialogContent>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <Box>
                        <Typography variant="h6">{strings.label.start}</Typography>
                        <Typography variant="body1">{start.format('YYYY-MM-DD HH:mm')}</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h6">{strings.label.end}</Typography>
                        <Typography variant="body1">{end.format('YYYY-MM-DD HH:mm')}</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h6">{strings.label.state}</Typography>
                        <Typography variant="body1">{strings.label.explanationState(explanation.state)}</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h6">{strings.label.licensePlate}</Typography>
                        {explanation.licensePlate !== null && <Typography variant="body1">{explanation.licensePlate}</Typography>}
                        {!explanation.licensePlate && <Typography variant="body1">{strings.label.licensePlateNone}</Typography>}
                        {explanation.licensePlateValid === true && <Typography variant="body1">{strings.label.licensePlateValid}</Typography>}
                        {explanation.licensePlateValid === false && <Typography variant="body1">{strings.label.licensePlateInvalid}</Typography>}
                    </Box>

                    <Box>
                        <Typography variant="h6">{strings.label.explanataion}</Typography>
                        <Typography
                            component="pre"
                            variant="body1"
                        >
                            {explanation.explanation}
                        </Typography>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                {explanation.state !== ExplanationState.Accepted && (
                    <Button
                        color="success"
                        onClick={handleAcceptClick}
                    >
                        {strings.button.accept}
                    </Button>
                )}
                {explanation.state !== ExplanationState.Rejected && (
                    <Button
                        color="error"
                        onClick={handleRejectClick}
                    >
                        {strings.button.reject}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(UserShiftEndExplanationDialog)
