import { DateTime } from '../DateTime'
import { Branch } from './Branch'
import { Draft } from './Draft'
import { Location } from './Location'
import { Status } from './Status'

export interface Order {
    readonly id: number
    readonly branch: Branch
    readonly status: Status
    readonly customer: string
    readonly licensePlate: string
    readonly licensePlateRecognized: string | null
    readonly photos: OrderPhoto[]
    readonly location: Location | null
    readonly draft: Draft | null
    readonly batteryLevel: number
    readonly createdAt: DateTime
    readonly savedAt: DateTime
    readonly flags: OrderFlag[]
}

export interface OrderPhoto {
    readonly id: number
    readonly orderId: number
    readonly filename: string
    readonly type: OrderPhotoType
    readonly createdAt: DateTime
    readonly uploadedAt: DateTime | null
    readonly clearedAt: DateTime | null
    readonly location: Location | null
    readonly isUploaded: boolean
    readonly isCleared: boolean
}

export enum OrderFlag {
    LicensePlateManualEntry = 'license_plate_manual_entry',
    InvalidLicensePlate = 'invalid_license_plate',
}

export enum OrderPhotoType {
    CarInspection = 'car_inspection',
    LicensePlateRecognition = 'license_plate_recognition',
}
