import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import {
    HistoryLog,
    HistoryLogType,
    Location,
    LocationFlag,
    LocationRequestType,
    NotificationType,
    Order,
    OrderFlag,
    OrderPhoto,
    OrderPhotoType,
    TaskDetails,
    UserDay,
    UserDayShift,
    UserDayShiftFlag,
} from '../../api/response'
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined'
import LocationOffIcon from '@mui/icons-material/LocationOffOutlined'
import DetailsIcon from '@mui/icons-material/ContentPasteSearchOutlined'
import _ from 'lodash'
import { formatDateTime } from '../../utils/formatDate'
import { Draft, DraftState } from '../../api/response/Draft'
import { TimePicker, TimeValidationError } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'
import { API } from '../../api/api'

const strings = {
    label: {
        history: 'Log',
        userLoggedIn: 'Logowanie',
        userLoggedOut: 'Wylogowanie',
        invalidPhoneToken: 'Błędny token telefonu',
        phoneTokenReset: 'Reset tokena telefonu',
        branchEntry: 'Wejście do oddziału',
        branchExit: 'Wyjście z oddziału',
        notificationSent: 'Powiadomienie',
        userLocate: 'Zlokalizowanie się',
        shiftStart: 'Rozpoczęcie czasu pracy',
        shiftStartEdited: 'Edytowane rozpoczęcie czasu pracy',
        shiftEnd: 'Zakończenie czasu pracy',
        shiftEndEdited: 'Edytowane zakończenie czasu pracy',
        taskStart: 'Rozpoczęcie zadania',
        taskEnd: 'Zakończenie zadania',
        order: 'Zlecenie',
        orderNumber: 'Zlecenie nr ',
        orderPhoto: 'Zdjęcie',
        location: 'Lokalizacja',
        draft: 'Dojazd na zlecenie',
        draftCreated: 'Oznaczenie dojazdu na zlecenie',
        draftUpdated: 'Dojazd na zlecenie - zakończenie',
        filterOrders: 'Pokaż tylko zlecenia wymagające uwagi',
        from: 'Od',
        to: 'Do',
        showPhotos: 'Pokaż zdjęcia',
    },
    field: {
        id: 'Identyfikator',
        user: 'Użytkownik',
        branch: 'Oddział',
        status: 'Status',
        customer: 'Klient',
        licensePlate: 'Numer rejestracyjny',
        batteryLevel: 'Poziom baterii',
        coordinates: 'Współrzędne geograficzne',
        requested: 'Żądanie zlokalizowania',
        realtimeOrSynced: 'Sposób zapisu',
        leftBranchDraft: 'Oznaczenie dojazdu na zlecenie',
        readAt: 'Data odczytu na telefonie',
        determinedAt: 'Data lokalizacji',
        placedAt: 'Data utworzenia na telefonie',
        savedAt: 'Data zapisu w bazie',
        accuracy: 'Dokładność',
        address: 'Adres',
        places: 'Miejsca',
        branchLocation: 'Lokalizacja',
        shiftSaveType: 'Sposób zapisu',
        date: 'Data',
        editedDate: 'Data edytowana',
        flag: 'Dodatkowa informacja',
        endReason: 'Powód automatycznego zakończenia',
        photoCreatedAt: 'Data wykonania na telefonie',
        photoUploadedAt: 'Data zapisu na serwerze',
        photoClearedAt: 'Data usunięcia z serwera',
        photoType: 'Typ zdjęcia',
        notificationType: 'Typ powiadomienia',
        notificationBody: 'Treść komunikatu',
        notificationSender: 'Sposób wysłania',
        locationRequestType: 'Typ żądania lokalizacji',
        orderDraft: 'Oznaczenie dojazdu na zlecenie',
        draftState: 'Aktualny stan',
        draftCreatedAt: 'Data oznaczenia dojazdu na zlecenie',
        draftUpdatedAt: 'Data zakończenia dojazdu na zlecenie',
    },
    value: {
        manualRequest: 'Ręczne',
        autoRequest: 'Automatyczne',
        silentRequest: 'Cicha lokalizacja',
        branchRequest: 'Weryfikacja oddziału',
        realtimeLocation: 'W czasie rzeczywistym',
        syncedLocation: 'Synchronizacja',
        insideBranch: 'Wewnątrz oddziału',
        outsideBranch: 'Poza oddziałem',
        byUser: 'Przez użytkownika',
        manualPanelEntry: 'Zmiana dodana ręcznie w panelu',
        startSystemTime: 'Rozpoczęcie według czasu serwera',
        endSystemTime: 'Zakończenie według czasu serwera',
        splitted: 'Pozycja podzielona',
        split: 'Z podziału',
        approved: 'Pozycja zatwierdzona',
        startOutsideBranch: 'Rozpoczęcie poza oddziałem',
        endOutsideBranch: 'Zakończenie poza oddziałem',
        startTooEarly: 'Rozpoczęto zbyt wcześnie',
        endTooLate: 'Zakończenie zbyt późne',
        startAdditionalBranch: 'Rozpoczęcie w oddziale dodatkowym',
        endAdditionalBranch: 'Zakończenie w oddziale dodatkowym',
        startInvalidTime: 'Błędny czas na telefonie',
        endInvalidTime: 'Błędny czas na telefonie',
        endBySystem: 'Automatyczne zakończenie przez system',
        outsideBranchNoOrders: 'Chwilowo poza oddziałem bez zlecenia',
        expired: 'Zmiana "przeterminowana"',
        systemNoLocation: 'Brak lokalizacji po automatycznym żądaniu lokalizacji',
        panelNoLocation: 'Brak lokalizacji po ręcznym żądaniu lokalizacji',
        branchCheckExpired: 'Brak zlokalizowania się po losowym żądaniu lokalizacji w oddziale',
        leftBranchNotAllowed: 'Opuszczono oddział',
        leftBranchWithoutOrder: 'Opuszczono oddział nie dodając zlecenia',
        leftBranchTooLong: 'Po dodaniu zlecenia za długo poza oddziałem',
        orderNotSyncedInTime: 'Zdjęcia do zlecenia nie zostały wysłane w wymaganym czasie',
        locationAccessForeground: 'Brak wymaganego dostępu do lokalizacji',
        locationAccessBackground: 'Zmieniono/cofnięto dostęp do lokalizacji',
        orderFormRelocation: 'W formularzu zlecenia wybrano status relokacji',
        manualEnd: 'Ręcznie zakończono w panelu',
        locationCheckOutsideBranch: 'Użytkownik zlokalizował się poza oddziałem',
        mockLocation: 'Imitowana lokalizacja',
        homeLocation: 'Przebywanie w miejscu zamieszkania',
        leftBranchWithDraft: 'Aktywny dojazd na zlecenie',
        leftBranchWithoutDraft: 'Brak dojazdu na zlecenie',
        invalidLicensePlate: 'Błędny numer rejestracyjny',
        licensePlateManualEntry: 'Numer rejestracyjny wpisany ręcznie',
        photoNotUploaded: 'Zdjęcie nie zostało jeszcze zapisane',
        shiftAutoEnd: 'Czas pracy automatycznie zakończony',
        shiftEndReminder: 'Przypomnienie o zakończeniu czasu pracy',
        taskCreated: 'Utworzono nowe zadanie',
        taskLate: 'Informacja o opóźnieniu w rozpoczęciu zadania',
        requestLocation: 'Żądanie zlokalizowania się',
        silentRequestLocation: 'Cicha próba zlokalizowania',
        pushNotification: 'Powiadomienie push',
        smsMessage: 'Wiadomość SMS',
        requestType: {
            none: 'Brak',
            manual: 'Ręczne',
            auto: 'Automatyczne',
            silent: 'Ciche',
            branch: 'Weryfikacja oddziału',
        },
        draftState: (state: DraftState): string => {
            switch (state) {
                case DraftState.active:
                    return 'Aktywny'
                case DraftState.canceled:
                    return 'Anulowany'
                case DraftState.done:
                    return 'Zakończony zleceniem'
                case DraftState.discarded:
                    return 'Odrzucony'
                default:
                    return ''
            }
        },
        photoType: (type: OrderPhotoType): string => {
            switch (type) {
                case OrderPhotoType.CarInspection:
                    return 'Samochód'
                case OrderPhotoType.LicensePlateRecognition:
                    return 'Tablica rejestracyjna'
                default:
                    return ''
            }
        },
    },
    button: {
        collapseAll: 'Zwiń wszystko',
        expandAll: 'Rozwiń wszystko',
    },
}

const titleForNotificationType = (type: NotificationType): string => {
    switch (type) {
        case NotificationType.shiftAutoEnd:
            return strings.value.shiftAutoEnd
        case NotificationType.shiftEndReminder:
            return strings.value.shiftEndReminder
        case NotificationType.taskCreated:
            return strings.value.taskCreated
        case NotificationType.taskLate:
            return strings.value.taskLate
        case NotificationType.requestLocation:
            return strings.value.requestLocation
        case NotificationType.silentRequestLocation:
            return strings.value.silentRequestLocation
        default:
            return ''
    }
}

const titleForLocationRequestType = (type: LocationRequestType): string => {
    switch (type) {
        case LocationRequestType.none:
            return strings.value.requestType.none
        case LocationRequestType.auto:
            return strings.value.requestType.auto
        case LocationRequestType.manual:
            return strings.value.requestType.manual
        case LocationRequestType.silent:
            return strings.value.requestType.silent
        case LocationRequestType.branch:
            return strings.value.requestType.branch
        default:
            return ''
    }
}

interface OwnProps {
    readonly data: UserDay
    readonly date: Dayjs
    readonly showLocation: (location: Location) => void
    readonly clearLocation: () => void
    readonly showOrder: (order: Order) => void
}

interface Entry {
    readonly id: string
    readonly date: Date
    readonly item: Order | OrderPhoto | UserDayShift | TaskDetails | Location | HistoryLog | Draft
    readonly type: EntryType
    readonly location: Location | null
    readonly index: number
}

enum EntryType {
    History,
    ShiftStart,
    ShiftStartEdited,
    ShiftEnd,
    ShiftEndEdited,
    TaskStart,
    TaskEnd,
    Order,
    OrderPhoto,
    Location,
    Draft,
}

const titleForEntryType = (type: EntryType): string => {
    switch (type) {
        case EntryType.History:
            return strings.label.history
        case EntryType.ShiftStart:
            return strings.label.shiftStart
        case EntryType.ShiftStartEdited:
            return strings.label.shiftStartEdited
        case EntryType.ShiftEnd:
            return strings.label.shiftEnd
        case EntryType.ShiftEndEdited:
            return strings.label.shiftEndEdited
        case EntryType.TaskStart:
            return strings.label.taskStart
        case EntryType.TaskEnd:
            return strings.label.taskEnd
        case EntryType.Order:
            return strings.label.order
        case EntryType.OrderPhoto:
            return strings.label.orderPhoto
        case EntryType.Location:
            return strings.label.location
        case EntryType.Draft:
            return strings.label.draft
        default:
            return ''
    }
}

export const LabelValue: React.FunctionComponent<{ label: string; value: string | undefined | null; color?: string | undefined }> = ({
    label,
    value,
    color,
}) => {
    if (value === undefined || value === null || value === '') {
        return null
    }

    return (
        <Box>
            <Typography
                variant="body1"
                color={color}
            >
                {value}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
            >
                {label}
            </Typography>
        </Box>
    )
}

const HistoryEntry: React.FunctionComponent<{ item: HistoryLog }> = ({ item }) => {
    return (
        <>
            {item.type === HistoryLogType.phoneTokenReset && (
                <LabelValue
                    label={strings.field.user}
                    value={item.details.userFullName}
                />
            )}
            {(item.type === HistoryLogType.branchEntry || item.type === HistoryLogType.branchExit) && (
                <LabelValue
                    label={strings.field.branch}
                    value={item.details.branchName}
                />
            )}
            {item.type === HistoryLogType.notificationSent && !!item.details.notificationType && (
                <LabelValue
                    label={strings.field.notificationType}
                    value={titleForNotificationType(item.details.notificationType)}
                />
            )}
            {item.type === HistoryLogType.notificationSent && !!item.details.body && (
                <LabelValue
                    label={strings.field.notificationBody}
                    value={item.details.body}
                />
            )}
            {item.type === HistoryLogType.notificationSent && !!item.details.sms && (
                <LabelValue
                    label={strings.field.notificationSender}
                    value={strings.value.smsMessage}
                />
            )}
            {item.type === HistoryLogType.notificationSent && !item.details.sms && (
                <LabelValue
                    label={strings.field.notificationSender}
                    value={strings.value.pushNotification}
                />
            )}
            {item.type === HistoryLogType.userLocate && !!item.details.locationRequestType && (
                <LabelValue
                    label={strings.field.locationRequestType}
                    value={titleForLocationRequestType(item.details.locationRequestType)}
                />
            )}
        </>
    )
}

const LocationEntry: React.FunctionComponent<{ item: Location }> = ({ item }) => {
    return (
        <>
            <LabelValue
                label={strings.field.coordinates}
                value={`${item.latitude.toString()}, ${item.longitude.toString()}`}
            />

            {item.accuracy >= 0 && (
                <LabelValue
                    label={strings.field.accuracy}
                    value={`${item.accuracy.toString()}`}
                />
            )}

            {item.flags.includes(LocationFlag.mock) && (
                <LabelValue
                    label={strings.field.flag}
                    value={strings.value.mockLocation}
                    color="red"
                />
            )}

            {!!item.address && (
                <LabelValue
                    label={strings.field.address}
                    value={item.address}
                />
            )}

            {!!item.places && (
                <LabelValue
                    label={strings.field.places}
                    value={item.places.join()}
                />
            )}

            {item.flags.includes(LocationFlag.autoRequest) && (
                <LabelValue
                    label={strings.field.requested}
                    value={strings.value.autoRequest}
                />
            )}

            {item.flags.includes(LocationFlag.manualRequest) && (
                <LabelValue
                    label={strings.field.requested}
                    value={strings.value.manualRequest}
                />
            )}

            {item.flags.includes(LocationFlag.silentRequest) && (
                <LabelValue
                    label={strings.field.requested}
                    value={strings.value.silentRequest}
                />
            )}

            {item.flags.includes(LocationFlag.branchRequest) && (
                <LabelValue
                    label={strings.field.requested}
                    value={strings.value.branchRequest}
                />
            )}

            {item.flags.includes(LocationFlag.insideBranch) && (
                <LabelValue
                    label={strings.field.branchLocation}
                    value={strings.value.insideBranch}
                />
            )}

            {item.flags.includes(LocationFlag.outsideBranch) && (
                <LabelValue
                    label={strings.field.branchLocation}
                    value={strings.value.outsideBranch}
                />
            )}

            {item.flags.includes(LocationFlag.realtime) && (
                <LabelValue
                    label={strings.field.realtimeOrSynced}
                    value={strings.value.realtimeLocation}
                />
            )}

            {item.flags.includes(LocationFlag.synced) && (
                <LabelValue
                    label={strings.field.realtimeOrSynced}
                    value={strings.value.syncedLocation}
                />
            )}

            {item.flags.includes(LocationFlag.leftBranchWithDraft) && (
                <LabelValue
                    label={strings.field.leftBranchDraft}
                    value={strings.value.leftBranchWithDraft}
                />
            )}

            {item.flags.includes(LocationFlag.leftBranchWithoutDraft) && (
                <LabelValue
                    label={strings.field.leftBranchDraft}
                    value={strings.value.leftBranchWithoutDraft}
                />
            )}

            <LabelValue
                label={strings.field.determinedAt}
                value={formatDateTime(item.determinedAt)}
            />

            <LabelValue
                label={strings.field.readAt}
                value={formatDateTime(item.createdAt)}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.savedAt)}
            />
        </>
    )
}

const ShiftStartEntry: React.FunctionComponent<{ item: UserDayShift }> = ({ item }) => {
    const flagElement = useCallback(
        (flag: UserDayShiftFlag, label: string, value: string) => {
            if (!item.flags.includes(flag)) {
                return null
            }

            return (
                <LabelValue
                    label={label}
                    value={value}
                />
            )
        },
        [item]
    )

    return (
        <>
            {!!item.startBranch && (
                <LabelValue
                    label={strings.field.branch}
                    value={item.startBranch.name}
                />
            )}

            {item.flags.includes(UserDayShiftFlag.ManualEntry) && (
                <LabelValue
                    label={strings.field.shiftSaveType}
                    value={strings.value.manualPanelEntry}
                />
            )}

            {!item.flags.includes(UserDayShiftFlag.ManualEntry) && (
                <LabelValue
                    label={strings.field.shiftSaveType}
                    value={strings.value.byUser}
                />
            )}

            <LabelValue
                label={strings.field.date}
                value={formatDateTime(item.start)}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.startSavedAt)}
            />

            {!!item.edited && (
                <>
                    <LabelValue
                        label={strings.field.editedDate}
                        value={formatDateTime(item.edited.start)}
                    />

                    <LabelValue
                        label={strings.field.savedAt}
                        value={formatDateTime(item.edited.editedAt)}
                    />
                </>
            )}

            {flagElement(UserDayShiftFlag.StartSystemTime, strings.field.flag, strings.value.startSystemTime)}
            {flagElement(UserDayShiftFlag.Splitted, strings.field.flag, strings.value.splitted)}
            {flagElement(UserDayShiftFlag.Split, strings.field.flag, strings.value.split)}
            {flagElement(UserDayShiftFlag.Approved, strings.field.flag, strings.value.approved)}
            {flagElement(UserDayShiftFlag.StartOutsideBranch, strings.field.flag, strings.value.startOutsideBranch)}
            {flagElement(UserDayShiftFlag.StartTooEarly, strings.field.flag, strings.value.startTooEarly)}
            {flagElement(UserDayShiftFlag.StartAdditionalBranch, strings.field.flag, strings.value.startAdditionalBranch)}
            {flagElement(UserDayShiftFlag.StartInvalidTime, strings.field.flag, strings.value.startInvalidTime)}
            {flagElement(UserDayShiftFlag.OutsideBranchNoOrders, strings.field.flag, strings.value.outsideBranchNoOrders)}
        </>
    )
}

const ShiftEndEntry: React.FunctionComponent<{ item: UserDayShift }> = ({ item }) => {
    const flagElement = useCallback(
        (flag: UserDayShiftFlag, label: string, value: string) => {
            if (!item.flags.includes(flag)) {
                return null
            }

            return (
                <LabelValue
                    label={label}
                    value={value}
                />
            )
        },
        [item]
    )

    return (
        <>
            {!!item.endBranch && (
                <LabelValue
                    label={strings.field.branch}
                    value={item.endBranch.name}
                />
            )}

            {item.flags.includes(UserDayShiftFlag.ManualEntry) && (
                <LabelValue
                    label={strings.field.shiftSaveType}
                    value={strings.value.manualPanelEntry}
                />
            )}

            {!item.flags.includes(UserDayShiftFlag.ManualEntry) && !item.flags.includes(UserDayShiftFlag.EndBySystem) && (
                <LabelValue
                    label={strings.field.shiftSaveType}
                    value={strings.value.byUser}
                />
            )}

            <LabelValue
                label={strings.field.date}
                value={formatDateTime(item.end) ?? '---'}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.endSavedAt) ?? '---'}
            />

            {!!item.edited && (
                <>
                    <LabelValue
                        label={strings.field.editedDate}
                        value={formatDateTime(item.edited.end)}
                    />

                    <LabelValue
                        label={strings.field.savedAt}
                        value={formatDateTime(item.edited.editedAt)}
                    />
                </>
            )}

            {flagElement(UserDayShiftFlag.EndSystemTime, strings.field.flag, strings.value.endSystemTime)}
            {flagElement(UserDayShiftFlag.Splitted, strings.field.flag, strings.value.splitted)}
            {flagElement(UserDayShiftFlag.Split, strings.field.flag, strings.value.split)}
            {flagElement(UserDayShiftFlag.Approved, strings.field.flag, strings.value.approved)}
            {flagElement(UserDayShiftFlag.EndOutsideBranch, strings.field.flag, strings.value.endOutsideBranch)}
            {flagElement(UserDayShiftFlag.EndTooLate, strings.field.flag, strings.value.endTooLate)}
            {flagElement(UserDayShiftFlag.EndAdditionalBranch, strings.field.flag, strings.value.endAdditionalBranch)}
            {flagElement(UserDayShiftFlag.EndInvalidTime, strings.field.flag, strings.value.endInvalidTime)}
            {flagElement(UserDayShiftFlag.OutsideBranchNoOrders, strings.field.flag, strings.value.outsideBranchNoOrders)}
            {flagElement(UserDayShiftFlag.EndBySystem, strings.field.flag, strings.value.endBySystem)}

            {flagElement(UserDayShiftFlag.Expired, strings.field.endReason, strings.value.expired)}
            {flagElement(UserDayShiftFlag.SystemNoLocation, strings.field.endReason, strings.value.systemNoLocation)}
            {flagElement(UserDayShiftFlag.PanelNoLocation, strings.field.endReason, strings.value.panelNoLocation)}
            {flagElement(UserDayShiftFlag.BranchCheckExpired, strings.field.endReason, strings.value.branchCheckExpired)}
            {flagElement(UserDayShiftFlag.LeftBranchNotAllowed, strings.field.endReason, strings.value.leftBranchNotAllowed)}
            {flagElement(UserDayShiftFlag.LeftBranchWithoutOrder, strings.field.endReason, strings.value.leftBranchWithoutOrder)}
            {flagElement(UserDayShiftFlag.LeftBranchTooLong, strings.field.endReason, strings.value.leftBranchTooLong)}
            {flagElement(UserDayShiftFlag.OrderNotSyncedInTime, strings.field.endReason, strings.value.orderNotSyncedInTime)}
            {flagElement(UserDayShiftFlag.LocationAccessForeground, strings.field.endReason, strings.value.locationAccessForeground)}
            {flagElement(UserDayShiftFlag.LocationAccessBackground, strings.field.endReason, strings.value.locationAccessBackground)}
            {flagElement(UserDayShiftFlag.OrderFormRelocation, strings.field.endReason, strings.value.orderFormRelocation)}
            {flagElement(UserDayShiftFlag.ManualEnd, strings.field.endReason, strings.value.manualEnd)}
            {flagElement(UserDayShiftFlag.LocationCheckOutsideBranch, strings.field.endReason, strings.value.locationCheckOutsideBranch)}
            {flagElement(UserDayShiftFlag.MockLocation, strings.field.endReason, strings.value.mockLocation)}
            {flagElement(UserDayShiftFlag.HomeLocation, strings.field.endReason, strings.value.homeLocation)}
        </>
    )
}

const ShiftStartEditedEntry: React.FunctionComponent<{ item: UserDayShift }> = ({ item }) => {
    if (item.edited === null) {
        return null
    }

    return (
        <>
            <LabelValue
                label={strings.field.date}
                value={formatDateTime(item.start)}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.startSavedAt)}
            />

            <LabelValue
                label={strings.field.editedDate}
                value={formatDateTime(item.edited.start)}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.edited.editedAt)}
            />
        </>
    )
}

const ShiftEndEditedEntry: React.FunctionComponent<{ item: UserDayShift }> = ({ item }) => {
    if (item.edited === null) {
        return null
    }

    return (
        <>
            <LabelValue
                label={strings.field.date}
                value={formatDateTime(item.end)}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.endSavedAt)}
            />

            <LabelValue
                label={strings.field.editedDate}
                value={formatDateTime(item.edited.end)}
            />

            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.edited.editedAt)}
            />
        </>
    )
}

const OrderEntry: React.FunctionComponent<{ item: Order }> = ({ item }) => {
    const flagElement = useCallback(
        (flag: OrderFlag, label: string, value: string, color: string | undefined) => {
            if (!item.flags.includes(flag)) {
                return null
            }

            return (
                <LabelValue
                    label={label}
                    value={value}
                    color={color}
                />
            )
        },
        [item]
    )

    return (
        <>
            <LabelValue
                label={strings.field.branch}
                value={item.branch.name}
            />
            <LabelValue
                label={strings.field.status}
                value={item.status.name}
            />
            <LabelValue
                label={strings.field.customer}
                value={item.customer}
            />
            <LabelValue
                label={strings.field.licensePlate}
                value={item.licensePlate}
            />
            <LabelValue
                label={strings.field.batteryLevel}
                value={item.batteryLevel.toString()}
            />
            <LabelValue
                label={strings.field.placedAt}
                value={formatDateTime(item.createdAt)}
            />
            <LabelValue
                label={strings.field.savedAt}
                value={formatDateTime(item.savedAt)}
            />

            {!!item.draft && (
                <LabelValue
                    label={strings.field.orderDraft}
                    value={formatDateTime(item.draft.createdAt)}
                />
            )}

            {flagElement(OrderFlag.InvalidLicensePlate, strings.field.flag, strings.value.invalidLicensePlate, 'red')}
            {flagElement(OrderFlag.LicensePlateManualEntry, strings.field.flag, strings.value.licensePlateManualEntry, 'red')}
        </>
    )
}

const OrderPhotoEntry: React.FunctionComponent<{ item: OrderPhoto }> = ({ item }) => {
    return (
        <>
            <LabelValue
                label={strings.field.photoCreatedAt}
                value={formatDateTime(item.createdAt)}
            />
            <LabelValue
                label={strings.field.photoUploadedAt}
                value={formatDateTime(item.uploadedAt) ?? strings.value.photoNotUploaded}
            />
            <LabelValue
                label={strings.field.photoType}
                value={strings.value.photoType(item.type)}
            />
            {item.clearedAt !== null && (
                <LabelValue
                    label={strings.field.photoClearedAt}
                    value={formatDateTime(item.clearedAt)}
                />
            )}
            {item.isUploaded && !item.isCleared && (
                <img
                    style={{ maxWidth: '100%' }}
                    alt={strings.label.orderPhoto}
                    src={API.orders.photo(item.orderId, 512, item.filename)}
                />
            )}
        </>
    )
}

const DraftEntry: React.FunctionComponent<{ item: Draft }> = ({ item }) => {
    return (
        <>
            <LabelValue
                label={strings.field.draftState}
                value={strings.value.draftState(item.state)}
            />

            <LabelValue
                label={strings.field.status}
                value={item.status.name}
            />

            <LabelValue
                label={strings.field.licensePlate}
                value={item.licensePlate}
            />

            <LabelValue
                label={strings.field.draftCreatedAt}
                value={formatDateTime(item.createdAt)}
            />

            {item.state !== DraftState.active && (
                <LabelValue
                    label={strings.field.draftUpdatedAt}
                    value={formatDateTime(item.updatedAt)}
                />
            )}
        </>
    )
}

const titleForEntry = (entry: Entry): string => {
    switch (entry.type) {
        case EntryType.History: {
            const item = entry.item as HistoryLog
            switch (item.type) {
                case HistoryLogType.userLoggedIn:
                    return strings.label.userLoggedIn
                case HistoryLogType.userLoggedOut:
                    return strings.label.userLoggedOut
                case HistoryLogType.invalidPhoneToken:
                    return strings.label.invalidPhoneToken
                case HistoryLogType.phoneTokenReset:
                    return strings.label.phoneTokenReset
                case HistoryLogType.branchEntry:
                    return strings.label.branchEntry
                case HistoryLogType.branchExit:
                    return strings.label.branchExit
                case HistoryLogType.notificationSent:
                    return strings.label.notificationSent
                case HistoryLogType.userLocate:
                    return strings.label.userLocate
                default:
                    return ''
            }
        }
        case EntryType.ShiftStart:
            return strings.label.shiftStart
        case EntryType.ShiftStartEdited:
            return strings.label.shiftStartEdited
        case EntryType.ShiftEnd:
            return strings.label.shiftEnd
        case EntryType.ShiftEndEdited:
            return strings.label.shiftEndEdited
        case EntryType.TaskStart:
            return strings.label.taskStart
        case EntryType.TaskEnd:
            return strings.label.taskEnd
        case EntryType.Order:
            return strings.label.orderNumber + (entry.index + 1).toString()
        case EntryType.OrderPhoto:
            return strings.label.orderPhoto
        case EntryType.Location:
            return strings.label.location
        case EntryType.Draft:
            if (entry.location !== null) {
                return strings.label.draftCreated
            }

            return strings.label.draftUpdated

        default:
            return ''
    }
}

const UserLogContent: React.FunctionComponent<OwnProps> = ({ data, date, showLocation, clearLocation, showOrder }) => {
    const [filterOrders, setFilterOrders] = useState<boolean>(false)
    const [types, setTypes] = useState<EntryType[]>(
        Object.values(EntryType)
            .filter((value) => !isNaN(Number(value)))
            .map((item) => item as EntryType)
    )
    const [startTime, setStartTime] = useState<Dayjs>(date.startOf('day'))
    const [endTime, setEndTime] = useState<Dayjs>(date.endOf('day'))
    const [selectedIds, setSelectedIds] = useState<string[]>([])

    const items = useMemo(() => {
        const entries: Entry[] = []

        data.logs.forEach((value, index) => {
            entries.push({
                id: value.id.toString(),
                date: value.createdAt,
                item: value,
                type: EntryType.History,
                location: value.location,
                index,
            })
        })

        data.shifts.forEach((value, index) => {
            entries.push({
                id: value.uuid,
                date: value.startSavedAt,
                item: value,
                type: EntryType.ShiftStart,
                location: value.startLocation,
                index,
            })

            if (value.endSavedAt !== null) {
                entries.push({
                    id: value.uuid,
                    date: value.endSavedAt,
                    item: value,
                    type: EntryType.ShiftEnd,
                    location: value.endLocation,
                    index,
                })
            }

            if (value.edited !== null) {
                entries.push({
                    id: value.uuid,
                    date: value.edited.start,
                    item: value,
                    type: EntryType.ShiftStartEdited,
                    location: value.startLocation,
                    index,
                })

                entries.push({
                    id: value.uuid,
                    date: value.edited.end,
                    item: value,
                    type: EntryType.ShiftEndEdited,
                    location: value.endLocation,
                    index,
                })
            }
        })

        data.tasks.forEach((value, index) => {
            if (value.startedAt !== null) {
                entries.push({
                    id: value.id.toString(),
                    date: value.startedAt,
                    item: value,
                    type: EntryType.TaskStart,
                    location: value.startLocation,
                    index,
                })
            }

            if (value.endedAt !== null) {
                entries.push({
                    id: value.id.toString(),
                    date: value.endedAt,
                    item: value,
                    type: EntryType.TaskEnd,
                    location: value.endLocation,
                    index,
                })
            }
        })

        data.orders.forEach((value, index) => {
            entries.push({
                id: value.id.toString(),
                date: value.createdAt,
                item: value,
                type: EntryType.Order,
                location: value.location,
                index,
            })

            value.photos.forEach((photo, index) => {
                entries.push({
                    id: `${value.id.toString()}/${photo.filename}`,
                    date: value.createdAt,
                    item: photo,
                    type: EntryType.OrderPhoto,
                    location: photo.location,
                    index,
                })
            })
        })

        data.locations.forEach((value, index) => {
            let date = value.savedAt
            if (value.flags.includes(LocationFlag.realtime)) {
                date = value.createdAt
            }

            entries.push({
                id: value.id.toString(),
                date: date,
                item: value,
                type: EntryType.Location,
                location: value,
                index,
            })
        })

        data.drafts.forEach((value, index) => {
            entries.push({
                id: value.id.toString(),
                date: value.createdAt,
                item: value,
                type: EntryType.Draft,
                location: value.location,
                index,
            })

            if (value.state !== DraftState.active) {
                entries.push({
                    id: value.id.toString(),
                    date: value.updatedAt,
                    item: value,
                    type: EntryType.Draft,
                    location: null,
                    index,
                })
            }
        })

        return _.sortBy(entries, ['date'])
    }, [data])

    const filteredItems = useMemo(() => {
        if (filterOrders) {
            return items.filter(
                (item) =>
                    dayjs(item.date) >= startTime &&
                    dayjs(item.date) <= endTime &&
                    item.type === EntryType.Order &&
                    ((item.item as Order).flags.includes(OrderFlag.InvalidLicensePlate) ||
                        (item.item as Order).flags.includes(OrderFlag.LicensePlateManualEntry))
            )
        }

        return items.filter((item) => types.includes(item.type) && dayjs(item.date) >= startTime && dayjs(item.date) <= endTime)
    }, [items, types, filterOrders, startTime, endTime])

    const setLocation = useCallback(
        (location: Location | null): void => {
            if (location !== null) {
                showLocation(location)
            } else {
                clearLocation()
            }
        },
        [showLocation, clearLocation]
    )

    const setOrder = useCallback(
        (order: Order): void => {
            showOrder(order)
        },
        [showOrder]
    )

    const toggleSelectedId = useCallback(
        (entryId: string): void => {
            setSelectedIds((ids) => {
                if (ids.includes(entryId)) {
                    return ids.filter((value) => value !== entryId)
                }

                return ids.concat(entryId)
            })
        },
        [items]
    )

    const collapseAll = useCallback((): void => {
        setSelectedIds([])
    }, [])

    const expandAll = useCallback((): void => {
        setSelectedIds(
            items.map((value): string => {
                return value.id
            })
        )
    }, [items])

    const onStartTimeChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (value && !context.validationError) {
            setStartTime(value)
        }
    }, [])

    const onEndTimeChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
        if (value && !context.validationError) {
            setEndTime(value)
        }
    }, [])

    useEffect(() => {
        setStartTime((value) => value.set('year', date.get('year')).set('month', date.get('month')).set('date', date.get('date')))
        setEndTime((value) => value.set('year', date.get('year')).set('month', date.get('month')).set('date', date.get('date')))
    }, [date])

    return (
        <Stack
            direction="column"
            spacing={1}
            divider={<Divider />}
        >
            <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1 }}
                flexWrap="wrap"
                useFlexGap={true}
            >
                {Object.values(EntryType)
                    .filter((value) => !isNaN(Number(value)))
                    .map((item) => item as EntryType)
                    .map((type) => (
                        <Chip
                            key={type.toString()}
                            label={titleForEntryType(type as EntryType)}
                            variant={types.includes(type) ? 'filled' : 'outlined'}
                            clickable={true}
                            onClick={(): void => {
                                if (types.includes(type)) {
                                    setTypes((value) => value.filter((value) => value !== type))
                                } else {
                                    setTypes(types.concat(type))
                                }
                            }}
                        />
                    ))}
            </Stack>

            <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1 }}
                flexWrap="wrap"
                useFlexGap={true}
            >
                <TimePicker
                    sx={{ width: '45%' }}
                    ampm={false}
                    ampmInClock={false}
                    label={strings.label.from}
                    format="HH:mm"
                    minutesStep={1}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    onChange={onStartTimeChange}
                    value={startTime}
                />

                <TimePicker
                    sx={{ width: '45%' }}
                    ampm={false}
                    ampmInClock={false}
                    label={strings.label.to}
                    format="HH:mm"
                    minutesStep={1}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    onChange={onEndTimeChange}
                    value={endTime}
                />
            </Stack>

            <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1 }}
                flexWrap="wrap"
                useFlexGap={true}
            >
                <Chip
                    label={strings.label.filterOrders}
                    variant={filterOrders ? 'filled' : 'outlined'}
                    clickable={true}
                    onClick={(): void => {
                        setFilterOrders((value) => !value)
                    }}
                />
            </Stack>

            <Stack
                direction="row"
                spacing={{ xs: 1, sm: 1 }}
                flexWrap="wrap"
                useFlexGap={true}
            >
                <Button
                    onClick={collapseAll}
                    variant="outlined"
                >
                    {strings.button.collapseAll}
                </Button>

                <Button
                    onClick={expandAll}
                    variant="outlined"
                >
                    {strings.button.expandAll}
                </Button>
            </Stack>

            {filteredItems.map((entry, index) => (
                <Card
                    key={index}
                    variant="outlined"
                    onMouseEnter={(): void => {
                        setLocation(entry.location)
                    }}
                >
                    <CardHeader
                        avatar={
                            <>
                                {!!entry.location && <LocationOnIcon />}
                                {!entry.location && <LocationOffIcon />}
                            </>
                        }
                        title={titleForEntry(entry)}
                        subheader={formatDateTime(entry.date)}
                        onClick={(): void => {
                            toggleSelectedId(entry.id)
                        }}
                        action={
                            entry.type === EntryType.Order && (
                                <Tooltip title={strings.label.showPhotos}>
                                    <IconButton
                                        onClick={(event): void => {
                                            event.stopPropagation()

                                            setOrder(entry.item as Order)
                                        }}
                                    >
                                        <DetailsIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    />

                    <Divider />

                    {selectedIds.includes(entry.id) && (
                        <CardContent>
                            <Stack
                                direction="column"
                                spacing={1}
                            >
                                <LabelValue
                                    label={strings.field.id}
                                    value={entry.id}
                                />

                                {entry.type === EntryType.History && <HistoryEntry item={entry.item as HistoryLog} />}
                                {entry.type === EntryType.Location && <LocationEntry item={entry.item as Location} />}
                                {entry.type === EntryType.ShiftStart && <ShiftStartEntry item={entry.item as UserDayShift} />}
                                {entry.type === EntryType.ShiftEnd && <ShiftEndEntry item={entry.item as UserDayShift} />}
                                {entry.type === EntryType.ShiftStartEdited && <ShiftStartEditedEntry item={entry.item as UserDayShift} />}
                                {entry.type === EntryType.ShiftEndEdited && <ShiftEndEditedEntry item={entry.item as UserDayShift} />}
                                {entry.type === EntryType.Order && <OrderEntry item={entry.item as Order} />}
                                {entry.type === EntryType.OrderPhoto && <OrderPhotoEntry item={entry.item as OrderPhoto} />}
                                {entry.type === EntryType.Draft && <DraftEntry item={entry.item as Draft} />}
                            </Stack>

                            {entry.type !== EntryType.Location && !!entry.location && (
                                <>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <LocationEntry item={entry.location} />
                                </>
                            )}
                        </CardContent>
                    )}
                </Card>
            ))}
        </Stack>
    )
}

export default React.memo(UserLogContent)
