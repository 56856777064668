import React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import { ExplanationState, HistoryEntryType, UserShiftHistory } from '../../api/response'
import { formatDateTime, formatTime } from '../../utils/formatDate'
import AddIcon from '@mui/icons-material/AddOutlined'
import RestoreIcon from '@mui/icons-material/RestoreOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import dayjs from 'dayjs'

const strings = {
    label: {
        title: 'Historia',
        content: (date: string, fullName: string): string => `Historia zmian z dnia ${date} dla ${fullName}`,
        added: 'Pozycja dodana',
        edited: 'Edycja',
        deleted: 'Pozycja usunięta/ukryta',
        restored: 'Pozycja przywrócona',
        period: 'Okres',
        explanationState: 'Status wyjaśnienia',
        explanationStateValue: (state: ExplanationState): string => {
            switch (state) {
                case ExplanationState.Awaiting:
                    return 'Oczekuje'
                case ExplanationState.Rejected:
                    return 'Odrzucone'
                case ExplanationState.Accepted:
                    return 'Zaakceptowane'
            }

            return '-'
        },
    },
    button: {
        close: 'Zamknij',
    },
}

export interface UserShiftHistoryDialogData {
    date: dayjs.Dayjs
    user: { id: number; fullName: string }
    items: UserShiftHistory[]
}

interface OwnProps {
    readonly open: boolean
    readonly data: UserShiftHistoryDialogData
    readonly closeDialog: () => void
}

const UserShiftHistoryDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { date, user, items } = data

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth={true}
            onClose={closeDialog}
        >
            <DialogTitle>{strings.label.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{strings.label.content(date.format('YYYY-MM-DD'), user.fullName)}</DialogContentText>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {item.entryType === HistoryEntryType.Added && (
                                    <Tooltip title={strings.label.added}>
                                        <AddIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === HistoryEntryType.Edited && (
                                    <Tooltip title={strings.label.edited}>
                                        <EditIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === HistoryEntryType.Removed && (
                                    <Tooltip title={strings.label.deleted}>
                                        <DeleteIcon />
                                    </Tooltip>
                                )}
                                {item.entryType === HistoryEntryType.Restored && (
                                    <Tooltip title={strings.label.restored}>
                                        <RestoreIcon />
                                    </Tooltip>
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Stack
                                        direction="column"
                                        spacing={0}
                                    >
                                        <Box>
                                            <Typography variant="body2">{strings.label.period}</Typography>
                                            <Typography variant="body1">{`${formatTime(item.start)} - ${formatTime(item.end)}`}</Typography>
                                        </Box>

                                        {item.explanationState !== null && (
                                            <Box>
                                                <Typography variant="body2">{strings.label.explanationState}</Typography>
                                                <Typography variant="body1">{strings.label.explanationStateValue(item.explanationState)}</Typography>
                                            </Box>
                                        )}
                                    </Stack>
                                }
                                secondary={`${formatDateTime(item.createdAt)}: ${item.user.firstName} ${item.user.lastName}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.close}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(UserShiftHistoryDialog)
