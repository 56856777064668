import React, { useCallback, useEffect } from 'react'
import { AdvancedMarker, InfoWindow, Map, Pin, useMap } from '@vis.gl/react-google-maps'
import { Box, Button, Typography, styled } from '@mui/material'
import {
    Branch,
    Location,
    LocationFlag,
    Order,
    OrderPhoto,
    TaskDetails,
    UserDay,
    UserDayShift,
    branchLatLngLiteral,
    geoPointLatLngLiteral,
    latLngLiteral,
} from '../../api/response'
import { Circle } from '../../components/Circle'
import BusinessIcon from '@mui/icons-material/BusinessOutlined'
import ShiftStartIcon from '@mui/icons-material/StartOutlined'
import ShiftEndIcon from '@mui/icons-material/KeyboardTabOutlined'
import TaskStartIcon from '@mui/icons-material/PlaylistPlayOutlined'
import TaskEndIcon from '@mui/icons-material/PlaylistRemoveOutlined'
import { formatDateTime } from '../../utils/formatDate'
import { InfoWindowData } from './UserDayPage'
import HomeIcon from '@mui/icons-material/HomeOutlined'

const strings = {
    label: {
        range: 'Zasięg',
        order: 'Zlecenie',
        orderPhoto: 'Zdjęcie',
        startShift: 'Rozpoczęcie czasu pracy',
        endShift: 'Zakończenie czasu pracy',
        startTask: 'Rozpoczęcie zadania',
        endTask: 'Zakończenie zadania',
        location: 'Lokalizacja',
    },
    button: {
        showLocation: 'Zobacz w Google Maps',
    },
}

const colors = {
    blue: { background: '#1d49c7', border: '#173a9f' },
    green: { background: '#3a833a', border: '#2e692e' },
    red: { background: '#ce3d2f', border: '#a53126' },
    redRed: { background: '#ff0000', border: '#bf0000' },
    darkRed: { background: '#82261b', border: '#681e16' },
    brown: { background: '#634630', border: '#4f3826' },
    orange: { background: '#e16632', border: '#c04d1c' },
    lightOrange: { background: '#ffa500', border: '#c78100' },
    yellow: { background: '#e0f005', border: '#a4b003' },
    white: '#ffffff',
    black: '#000000',
}

const StyledDot = styled(Box)(() => ({
    width: 14,
    height: 14,
    position: 'absolute',
    top: 0,
    left: 0,
    background: colors.brown.background,
    borderColor: colors.brown.border,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
}))

const StyledOrangeDot = styled(Box)(() => ({
    width: 14,
    height: 14,
    position: 'absolute',
    top: 0,
    left: 0,
    background: colors.lightOrange.background,
    borderColor: colors.lightOrange.border,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
}))

const StyledRedDot = styled(Box)(() => ({
    width: 14,
    height: 14,
    position: 'absolute',
    top: 0,
    left: 0,
    background: colors.redRed.background,
    borderColor: colors.redRed.border,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
}))

interface OwnProps {
    readonly data: UserDay
    readonly location: Location | null
    readonly infoWindow: InfoWindowData | null
    openInfoWindow: (markerId: string, location: google.maps.LatLngLiteral) => void
    closeInfoWindow: () => void
}

const UserMapContent: React.FunctionComponent<OwnProps> = ({ data, location, infoWindow, openInfoWindow, closeInfoWindow }) => {
    const map = useMap()

    const keyForBranch = useCallback((branch: Branch): string => `branch_${branch.id}`, [])
    const keyForOrder = useCallback((order: Order): string => `order_${order.id}`, [])
    const keyForOrderPhoto = useCallback((orderPhoto: OrderPhoto): string => `orderPhoto_${orderPhoto.filename}`, [])
    const keyForShift = useCallback((shift: UserDayShift): string => `shift_${shift.uuid}`, [])
    const keyForShiftStart = useCallback((shift: UserDayShift): string => `shift_start_${shift.uuid}`, [])
    const keyForShiftEnd = useCallback((shift: UserDayShift): string => `shift_end_${shift.uuid}`, [])
    const keyForTask = useCallback((task: TaskDetails): string => `task_${task.id}`, [])
    const keyForTaskStart = useCallback((task: TaskDetails): string => `task_start_${task.id}`, [])
    const keyForTaskEnd = useCallback((task: TaskDetails): string => `task_end_${task.id}`, [])
    const keyForLocation = useCallback((location: Location): string => `location_${location.id}`, [])

    const showLocation = (location: Location | null): void => {
        if (location === null) {
            return
        }

        window.open(`https://www.google.com/maps?q=${location.latitude},${location.longitude}`, '_blank', 'noreferrer')
    }

    useEffect(() => {
        if (location !== null) {
            map?.panTo(latLngLiteral(location))
        }
    }, [location])

    return (
        <Map
            defaultZoom={14}
            defaultCenter={branchLatLngLiteral(data.user.branch)}
            gestureHandling={'greedy'}
            style={{ height: 800 }}
            mapId="7a0c8b575cfb7279"
        >
            <AdvancedMarker
                position={branchLatLngLiteral(data.user.branch)}
                title={data.user.branch.name}
                onClick={(): void => {
                    openInfoWindow(keyForBranch(data.user.branch), branchLatLngLiteral(data.user.branch))
                }}
            >
                <Pin
                    background={colors.orange.background}
                    borderColor={colors.orange.border}
                    glyphColor={colors.white}
                >
                    <BusinessIcon style={{ width: 16 }} />
                </Pin>
            </AdvancedMarker>

            {!!infoWindow && infoWindow.markerId === keyForBranch(data.user.branch) && (
                <InfoWindow
                    position={infoWindow.location}
                    pixelOffset={new google.maps.Size(0, -40)}
                    onCloseClick={closeInfoWindow}
                >
                    <Typography variant="h6">{data.user.branch.name}</Typography>
                    <Typography>
                        {strings.label.range} {data.user.branch.range}m
                    </Typography>
                </InfoWindow>
            )}

            <Circle
                radius={data.user.branch.range}
                center={branchLatLngLiteral(data.user.branch)}
                strokeColor={'#0c4cb3'}
                strokeOpacity={1}
                strokeWeight={3}
                fillColor={'#3b82f6'}
                fillOpacity={0.3}
                clickable={false}
            />

            {!!data.user.branchRange && (
                <Circle
                    radius={data.user.branchRange}
                    center={branchLatLngLiteral(data.user.branch)}
                    strokeColor={'#681e16'}
                    strokeOpacity={1}
                    strokeWeight={3}
                    clickable={false}
                    fillColor={'#82261b'}
                    fillOpacity={0.3}
                />
            )}

            {!!data.user.home && (
                <>
                    <AdvancedMarker
                        position={geoPointLatLngLiteral(data.user.home.location)}
                        title={data.user.home.address}
                    >
                        <Pin
                            background={colors.yellow.border}
                            borderColor={colors.black}
                            glyphColor={colors.white}
                        >
                            <HomeIcon style={{ width: 16 }} />
                        </Pin>
                    </AdvancedMarker>

                    <Circle
                        radius={data.user.home.range}
                        center={geoPointLatLngLiteral(data.user.home.location)}
                        strokeColor={colors.yellow.border}
                        strokeOpacity={1}
                        strokeWeight={3}
                        clickable={false}
                        fillColor={colors.yellow.background}
                        fillOpacity={0.3}
                    />
                </>
            )}

            {data.locations.map((location) => (
                <React.Fragment key={keyForLocation(location)}>
                    <AdvancedMarker
                        position={latLngLiteral(location)}
                        title={`${strings.label.location} - ${formatDateTime(location.determinedAt)}`}
                        onClick={(): void => {
                            openInfoWindow(keyForLocation(location), latLngLiteral(location))
                        }}
                    >
                        {(!location.places || location.places.length === 0) && !location.flags.includes(LocationFlag.outsideBranch) && (
                            <StyledOrangeDot />
                        )}
                        {(!location.places || location.places.length === 0) && location.flags.includes(LocationFlag.outsideBranch) && <StyledDot />}
                        {!!location.places && location.places.length > 0 && <StyledRedDot />}
                    </AdvancedMarker>

                    {!!infoWindow && infoWindow.markerId === keyForLocation(location) && (
                        <InfoWindow
                            position={infoWindow.location}
                            pixelOffset={new google.maps.Size(0, -8)}
                            onCloseClick={closeInfoWindow}
                        >
                            <Typography variant="h6">{strings.label.location}</Typography>
                            <Typography variant="subtitle2">{location.id}</Typography>

                            {!!location.address && <Typography>{location.address}</Typography>}
                            <Typography variant="caption">{formatDateTime(location.determinedAt)}</Typography>

                            {!!location.places && location.places.map((item, index) => <li key={index}>{item}</li>)}

                            <Box>
                                <Button
                                    variant="text"
                                    onClick={(): void => {
                                        showLocation(location)
                                    }}
                                >
                                    {strings.button.showLocation}
                                </Button>
                            </Box>
                        </InfoWindow>
                    )}
                </React.Fragment>
            ))}

            {data.orders.map((order: Order, orderIndex) => (
                <React.Fragment key={keyForOrder(order)}>
                    {order.photos.map((orderPhoto: OrderPhoto, photoIndex) => (
                        <React.Fragment key={keyForOrderPhoto(orderPhoto)}>
                            {!!orderPhoto.location && (
                                <>
                                    <AdvancedMarker
                                        position={latLngLiteral(orderPhoto.location)}
                                        title={strings.label.orderPhoto}
                                        onClick={(): void => {
                                            openInfoWindow(keyForOrderPhoto(orderPhoto), latLngLiteral(orderPhoto.location!!))
                                        }}
                                    >
                                        <Pin
                                            background={colors.darkRed.background}
                                            borderColor={colors.darkRed.border}
                                            glyphColor={colors.white}
                                        >
                                            {orderIndex + 1}
                                        </Pin>
                                    </AdvancedMarker>

                                    {!!infoWindow && infoWindow.markerId === keyForOrderPhoto(orderPhoto) && (
                                        <InfoWindow
                                            position={infoWindow.location}
                                            pixelOffset={new google.maps.Size(0, -40)}
                                            onCloseClick={closeInfoWindow}
                                        >
                                            <Typography variant="h6">{strings.label.orderPhoto}</Typography>
                                            <Typography variant="subtitle1">
                                                {order.id} / {photoIndex + 1}
                                            </Typography>

                                            {!!orderPhoto.location.address && <Typography>{orderPhoto.location.address}</Typography>}
                                            <Typography variant="caption">{formatDateTime(orderPhoto.location.determinedAt)}</Typography>

                                            <Box>
                                                <Button
                                                    variant="text"
                                                    onClick={(): void => {
                                                        showLocation(orderPhoto.location)
                                                    }}
                                                >
                                                    {strings.button.showLocation}
                                                </Button>
                                            </Box>
                                        </InfoWindow>
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}

                    {!!order.location && (
                        <>
                            <AdvancedMarker
                                position={latLngLiteral(order.location)}
                                title={strings.label.order}
                                onClick={(): void => {
                                    openInfoWindow(keyForOrder(order), latLngLiteral(order.location!!))
                                }}
                            >
                                <Pin
                                    background={colors.red.background}
                                    borderColor={colors.red.border}
                                    glyphColor={colors.white}
                                >
                                    {orderIndex + 1}
                                </Pin>
                            </AdvancedMarker>

                            {!!infoWindow && infoWindow.markerId === keyForOrder(order) && (
                                <InfoWindow
                                    position={infoWindow.location}
                                    pixelOffset={new google.maps.Size(0, -40)}
                                    onCloseClick={closeInfoWindow}
                                >
                                    <Typography variant="h6">{strings.label.order}</Typography>
                                    <Typography variant="subtitle1">{order.id}</Typography>
                                    <Typography variant="subtitle1">{order.status.name}</Typography>
                                    <Typography variant="subtitle1">{order.customer}</Typography>
                                    <Typography variant="subtitle1">{order.licensePlate}</Typography>

                                    {!!order.location.address && <Typography>{order.location.address}</Typography>}
                                    <Typography variant="caption">{formatDateTime(order.location.determinedAt)}</Typography>

                                    <Box>
                                        <Button
                                            variant="text"
                                            onClick={(): void => {
                                                showLocation(order.location)
                                            }}
                                        >
                                            {strings.button.showLocation}
                                        </Button>
                                    </Box>
                                </InfoWindow>
                            )}
                        </>
                    )}
                </React.Fragment>
            ))}

            {data.shifts.map((shift: UserDayShift) => (
                <React.Fragment key={keyForShift(shift)}>
                    {!!shift.startLocation && (
                        <>
                            <AdvancedMarker
                                position={latLngLiteral(shift.startLocation)}
                                title={strings.label.startShift}
                                onClick={(): void => {
                                    openInfoWindow(keyForShiftStart(shift), latLngLiteral(shift.startLocation!!))
                                }}
                            >
                                <Pin
                                    background={colors.green.background}
                                    borderColor={colors.green.border}
                                    glyphColor={colors.white}
                                >
                                    <ShiftStartIcon style={{ width: 16 }} />
                                </Pin>
                            </AdvancedMarker>

                            {!!infoWindow && infoWindow.markerId === keyForShiftStart(shift) && (
                                <InfoWindow
                                    position={infoWindow.location}
                                    pixelOffset={new google.maps.Size(0, -40)}
                                    onCloseClick={closeInfoWindow}
                                >
                                    <Typography variant="h6">{strings.label.startShift}</Typography>
                                    <Typography variant="subtitle2">{shift.uuid}</Typography>

                                    {!!shift.startLocation.address && <Typography>{shift.startLocation.address}</Typography>}
                                    <Typography variant="caption">{formatDateTime(shift.startLocation.determinedAt)}</Typography>

                                    <Box>
                                        <Button
                                            variant="text"
                                            onClick={(): void => {
                                                showLocation(shift.startLocation)
                                            }}
                                        >
                                            {strings.button.showLocation}
                                        </Button>
                                    </Box>
                                </InfoWindow>
                            )}
                        </>
                    )}

                    {!!shift.endLocation && (
                        <>
                            <AdvancedMarker
                                position={latLngLiteral(shift.endLocation)}
                                title={strings.label.endShift}
                                onClick={(): void => {
                                    openInfoWindow(keyForShiftEnd(shift), latLngLiteral(shift.endLocation!!))
                                }}
                            >
                                <Pin
                                    background={colors.green.background}
                                    borderColor={colors.green.border}
                                    glyphColor={colors.white}
                                >
                                    <ShiftEndIcon style={{ width: 16 }} />
                                </Pin>
                            </AdvancedMarker>

                            {!!infoWindow && infoWindow.markerId === keyForShiftEnd(shift) && (
                                <InfoWindow
                                    position={infoWindow.location}
                                    pixelOffset={new google.maps.Size(0, -40)}
                                    onCloseClick={closeInfoWindow}
                                >
                                    <Typography variant="h6">{strings.label.endShift}</Typography>
                                    <Typography variant="subtitle2">{shift.uuid}</Typography>

                                    {!!shift.endLocation.address && <Typography>{shift.endLocation.address}</Typography>}
                                    <Typography variant="caption">{formatDateTime(shift.endLocation.determinedAt)}</Typography>

                                    <Box>
                                        <Button
                                            variant="text"
                                            onClick={(): void => {
                                                showLocation(shift.endLocation)
                                            }}
                                        >
                                            {strings.button.showLocation}
                                        </Button>
                                    </Box>
                                </InfoWindow>
                            )}
                        </>
                    )}
                </React.Fragment>
            ))}

            {data.tasks.map((task: TaskDetails) => (
                <React.Fragment key={keyForTask(task)}>
                    {!!task.startLocation && (
                        <>
                            <AdvancedMarker
                                position={latLngLiteral(task.startLocation)}
                                title={strings.label.startTask}
                                onClick={(): void => {
                                    openInfoWindow(keyForTaskStart(task), latLngLiteral(task.startLocation!!))
                                }}
                            >
                                <Pin
                                    background={colors.blue.background}
                                    borderColor={colors.blue.border}
                                    glyphColor={colors.white}
                                >
                                    <TaskStartIcon style={{ width: 16 }} />
                                </Pin>
                            </AdvancedMarker>

                            {!!infoWindow && infoWindow.markerId === keyForTaskStart(task) && (
                                <InfoWindow
                                    position={infoWindow.location}
                                    pixelOffset={new google.maps.Size(0, -40)}
                                    onCloseClick={closeInfoWindow}
                                >
                                    <Typography variant="h6">{strings.label.startTask}</Typography>
                                    <Typography variant="subtitle2">{task.id}</Typography>

                                    {!!task.startLocation.address && <Typography>{task.startLocation.address}</Typography>}
                                    <Typography variant="caption">{formatDateTime(task.startLocation.determinedAt)}</Typography>

                                    <Box>
                                        <Button
                                            variant="text"
                                            onClick={(): void => {
                                                showLocation(task.startLocation)
                                            }}
                                        >
                                            {strings.button.showLocation}
                                        </Button>
                                    </Box>
                                </InfoWindow>
                            )}
                        </>
                    )}

                    {!!task.endLocation && (
                        <>
                            <AdvancedMarker
                                position={latLngLiteral(task.endLocation)}
                                title={strings.label.endTask}
                                onClick={(): void => {
                                    openInfoWindow(keyForTaskEnd(task), latLngLiteral(task.endLocation!!))
                                }}
                            >
                                <Pin
                                    background={colors.blue.background}
                                    borderColor={colors.blue.border}
                                    glyphColor={colors.white}
                                >
                                    <TaskEndIcon style={{ width: 16 }} />
                                </Pin>
                            </AdvancedMarker>

                            {!!infoWindow && infoWindow.markerId === keyForTaskEnd(task) && (
                                <InfoWindow
                                    position={infoWindow.location}
                                    pixelOffset={new google.maps.Size(0, -40)}
                                    onCloseClick={closeInfoWindow}
                                >
                                    <Typography variant="h6">{strings.label.endTask}</Typography>
                                    <Typography variant="subtitle2">{task.id}</Typography>

                                    {!!task.endLocation.address && <Typography>{task.endLocation.address}</Typography>}
                                    <Typography variant="caption">{formatDateTime(task.endLocation.determinedAt)}</Typography>

                                    <Box>
                                        <Button
                                            variant="text"
                                            onClick={(): void => {
                                                showLocation(task.endLocation)
                                            }}
                                        >
                                            {strings.button.showLocation}
                                        </Button>
                                    </Box>
                                </InfoWindow>
                            )}
                        </>
                    )}
                </React.Fragment>
            ))}

            {!!location && (
                <AdvancedMarker
                    position={latLngLiteral(location)}
                    zIndex={9999999}
                >
                    <StyledRedDot />
                </AdvancedMarker>
            )}
        </Map>
    )
}

export default React.memo(UserMapContent)
