import { DateTime } from '../DateTime'
import { ExplanationState } from './Schedule'
import { UserInfo } from './UserInfo'

export interface UserShiftHistory {
    readonly user: UserInfo
    readonly start: DateTime
    readonly end: DateTime
    readonly explanationState: ExplanationState | null
    readonly entryType: HistoryEntryType
    readonly createdAt: DateTime
}

export interface ScheduleShiftHistory {
    readonly user: UserInfo
    readonly start: DateTime
    readonly end: DateTime
    readonly entryType: ScheduleUserDayShiftHistoryEntryType
    readonly createdAt: DateTime
}

export enum HistoryEntryType {
    Added = 'added',
    Edited = 'edited',
    Removed = 'removed',
    Restored = 'restored',
}

export enum ScheduleUserDayShiftHistoryEntryType {
    Added = 'added',
    Edited = 'edited',
    Removed = 'removed',
    Restored = 'restored',
    ReplacementAdded = 'replacement_added',
    ReplacementEdited = 'replacement_edited',
    ReplacementRemoved = 'replacement_removed',
}
