import { DateTime } from '../DateTime'
import { Branch } from './Branch'
import { Holiday } from './Holiday'
import { JobPosition } from './JobPosition'
import { Location } from './Location'
import { UserInfo } from './UserInfo'

export interface Schedule {
    readonly id: string
    readonly branch: Branch
    readonly year: number
    readonly month: number
    readonly status: ScheduleStatus
    readonly holidays: Holiday[]
    readonly groups: ScheduleGroup[]
}

export interface ScheduleGroup {
    readonly jobPosition: JobPosition
    readonly users: ScheduleUser[]
}

export interface ScheduleUser {
    readonly id: number
    readonly firstName: string
    readonly lastName: string
    readonly status: ScheduleUserStatus
    readonly disabledDays: number[]
    readonly days: ScheduleUserDay[]
}

export interface ScheduleUserDay {
    readonly day: number
    readonly note: string | null
    readonly scheduleShifts: ScheduleUserDayShift[]
    readonly userShifts: UserDayShift[]
}

export interface ScheduleUserDayShift {
    readonly id: number
    readonly start: DateTime
    readonly end: DateTime
    readonly note: string | null
    readonly flags: ScheduleUserDayShiftFlag[]
    readonly replacementFor: UserInfo | null
    readonly isActive: boolean
}

export interface UserDayShift {
    readonly uuid: string
    readonly start: DateTime
    readonly end: DateTime | null
    readonly period: number
    readonly startBranch: Branch | null
    readonly endBranch: Branch | null
    readonly startLocation: Location | null
    readonly endLocation: Location | null
    readonly edited: ShiftEdited | null
    readonly flags: UserDayShiftFlag[]
    readonly startSavedAt: DateTime
    readonly endSavedAt: DateTime | null
    readonly endExplanation: ShiftEndExplanation | null
    readonly isHidden: boolean
}

export interface ShiftEdited {
    readonly start: DateTime
    readonly end: DateTime
    readonly period: number
    readonly editedAt: DateTime
}

export interface ShiftEndExplanation {
    readonly explanation: string
    readonly licensePlate: string | null
    readonly licensePlateValid: boolean | null
    readonly state: ExplanationState
    readonly createdAt: DateTime
    readonly updatedAt: DateTime
}

export enum ScheduleStatus {
    Active = 'active',
    Closed = 'closed',
}

export enum ScheduleUserStatus {
    Active = 'active',
    Fired = 'fired',
}

export enum ScheduleUserDayShiftFlag {
    Morning = 'morning',
    Night = 'night',
    Training = 'training',
    Late = 'late',
    Absence = 'absence',
    Checked = 'checked',
    ExplanationsChecked = 'explanations_checked',
    Replacement = 'replacement',
    CanceledDrafts = 'canceled_drafts',
}

export enum UserDayShiftFlag {
    StartSystemTime = 'start_system_time',
    EndSystemTime = 'end_system_time',
    EndBySystem = 'end_by_system',
    ManualEntry = 'manual_entry',
    Splitted = 'splitted',
    Split = 'split',
    Approved = 'approved',
    StartOutsideBranch = 'start_outside_branch',
    EndOutsideBranch = 'end_outside_branch',
    OutsideBranchNoOrders = 'outside_branch_no_orders',
    StartAdditionalBranch = 'start_additional_branch',
    EndAdditionalBranch = 'end_additional_branch',
    StartInvalidTime = 'start_invalid_time',
    EndInvalidTime = 'end_invalid_time',
    QualifiedForOvertime = 'qualified_for_overtime',

    Expired = 'expired',
    SystemNoLocation = 'system_no_location',
    PanelNoLocation = 'panel_no_location',
    BranchCheckExpired = 'branch_check_expired',
    LeftBranchNotAllowed = 'left_branch_not_allowed',
    LeftBranchWithoutOrder = 'left_branch_without_order',
    LeftBranchTooLong = 'left_branch_too_long',
    OrderNotSyncedInTime = 'order_not_synced_in_time',
    LocationAccessForeground = 'location_access_foreground',
    LocationAccessBackground = 'location_access_background',
    OrderFormRelocation = 'order_form_relocation',
    ManualEnd = 'manual_end',
    LocationCheckOutsideBranch = 'location_check_outside_branch',
    MockLocation = 'mock_location',
    HomeLocation = 'home_location',
    DraftCanceled = 'draft_canceled',
    OrderDraftInvalid = 'order_draft_invalid',
    LeftBranchWithoutDraft = 'left_branch_without_draft',

    // deprecated

    StartTooEarly = 'start_too_early',
    EndTooLate = 'end_too_late',
}

export enum ExplanationState {
    Awaiting = 'awaiting',
    Rejected = 'rejected',
    Accepted = 'accepted',
}
