import React, { useCallback, useState } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Order, OrderPhoto, OrderPhotoType } from '../../api/response'
import NoPhotoIcon from '@mui/icons-material/ImageNotSupportedOutlined'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { formatDateTime } from '../../utils/formatDate'
import { API } from '../../api/api'
import { LabelValue } from './UserLogContent'

const strings = {
    label: {
        title: (order: Order): string => `Zlecenie #${order.id} - ${order.branch.name}/${order.customer}/${order.licensePlate}`,
        orderPhoto: 'Zdjęcie',
        close: 'Zamknik',
    },
    field: {
        id: 'Identyfikator',
        photoCreatedAt: 'Data wykonania na telefonie',
        photoUploadedAt: 'Data zapisu na serwerze',
        photoClearedAt: 'Data usunięcia z serwera',
        photoType: 'Typ zdjęcia',
    },
    value: {
        photoNotUploaded: 'Zdjęcie nie zostało jeszcze zapisane',
        photoType: (type: OrderPhotoType): string => {
            switch (type) {
                case OrderPhotoType.CarInspection:
                    return 'Samochód'
                case OrderPhotoType.LicensePlateRecognition:
                    return 'Tablica rejestracyjna'
                default:
                    return ''
            }
        },
    },
}

const OrderPhotoView: React.FunctionComponent<{ item: OrderPhoto }> = ({ item }) => {
    return (
        <Stack
            direction="row"
            spacing={2}
        >
            <Stack direction="column">
                <LabelValue
                    label={strings.field.id}
                    value={item.id.toString()}
                />
                <LabelValue
                    label={strings.field.photoCreatedAt}
                    value={formatDateTime(item.createdAt)}
                />
                <LabelValue
                    label={strings.field.photoUploadedAt}
                    value={formatDateTime(item.uploadedAt) ?? strings.value.photoNotUploaded}
                />
                <LabelValue
                    label={strings.field.photoType}
                    value={strings.value.photoType(item.type)}
                />
                {item.clearedAt !== null && (
                    <LabelValue
                        label={strings.field.photoClearedAt}
                        value={formatDateTime(item.clearedAt)}
                    />
                )}
            </Stack>

            {item.isUploaded && !item.isCleared && (
                <Box>
                    <a
                        target="_blank"
                        href={API.orders.photo(item.orderId, 0, item.filename)}
                        rel="noreferrer"
                    >
                        <img
                            style={{ maxWidth: '100%' }}
                            alt={strings.label.orderPhoto}
                            src={API.orders.photo(item.orderId, 512, item.filename)}
                        />
                    </a>
                </Box>
            )}
        </Stack>
    )
}

interface OwnProps {
    readonly order: Order
    close: () => void
}

const UserOrderContent: React.FunctionComponent<OwnProps> = ({ order, close }) => {
    const [selectedPhoto, setSelectedPhoto] = useState<OrderPhoto | null>(null)

    const selectPhoto = useCallback((photo: OrderPhoto): void => {
        setSelectedPhoto((value) => {
            if (value !== null && value.id === photo.id) {
                return null
            }
            return photo
        })
    }, [])

    return (
        <Stack
            direction="column"
            spacing={1}
        >
            <Stack
                direction="row"
                alignItems="center"
            >
                <Tooltip title={strings.label.close}>
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                <Typography variant="h5">{strings.label.title(order)}</Typography>
            </Stack>
            <Stack
                spacing={1}
                direction="row"
                flexWrap="wrap"
                useFlexGap={true}
            >
                {order.photos.map((photo, index) => (
                    <>
                        {photo.isUploaded && !photo.isCleared && (
                            <img
                                key={index}
                                width={128}
                                height={128}
                                alt={strings.label.orderPhoto}
                                src={API.orders.photo(photo.orderId, 128, photo.filename)}
                                onClick={(): void => {
                                    selectPhoto(photo)
                                }}
                            />
                        )}
                        {(!photo.isUploaded || photo.isCleared) && (
                            <Box
                                sx={{ textAlign: 'center' }}
                                width={128}
                                height={128}
                                onClick={(): void => {
                                    selectPhoto(photo)
                                }}
                            >
                                <NoPhotoIcon sx={{ width: 112, height: 112, padding: 1 }} />
                            </Box>
                        )}
                    </>
                ))}
            </Stack>
            {selectedPhoto !== null && <OrderPhotoView item={selectedPhoto} />}
        </Stack>
    )
}

export default React.memo(UserOrderContent)
